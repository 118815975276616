module.exports = [{
      plugin: require('../node_modules/@whitespace/gatsby-plugin-page-wrapper/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@whitespace/gatsby-plugin-i18next/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"sv","languages":["sv","en"]},
    },{
      plugin: require('../node_modules/@whitespace/gatsby-theme-wordpress-basic/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/var/lib/jenkins/workspace/headless-intranet.devspace.se","fragmentsDir":"/var/lib/jenkins/workspace/headless-intranet.devspace.se/src/fragments","i18next":{"defaultLanguage":"sv","languages":["sv","en"]},"wp":{"url":"https://cms.headless-intranet.devspace.se","nodesPerFetch":250},"auth":{"requireLogin":false},"postCss":{"postcssOptions":{"plugins":[null]}}},
    },{
      plugin: require('../node_modules/@municipio/gatsby-theme-basic/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/var/lib/jenkins/workspace/headless-intranet.devspace.se","fragmentsDir":"/var/lib/jenkins/workspace/headless-intranet.devspace.se/src/fragments","i18next":{"defaultLanguage":"sv","languages":["sv","en"]},"wp":{"url":"https://cms.headless-intranet.devspace.se","nodesPerFetch":250},"auth":{"requireLogin":false},"postCss":{"postcssOptions":{"plugins":[null]}}},
    },{
      plugin: require('../node_modules/@municipio/gatsby-theme-intranet/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/var/lib/jenkins/workspace/headless-intranet.devspace.se","fragmentsDir":"/var/lib/jenkins/workspace/headless-intranet.devspace.se/src/fragments","i18next":{"defaultLanguage":"sv","languages":["sv","en"]},"wp":{"url":"https://cms.headless-intranet.devspace.se","nodesPerFetch":250},"auth":{"requireLogin":false},"postCss":{"postcssOptions":{"plugins":[null]}}},
    }]
